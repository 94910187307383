import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [0,2,3,5];

export const dictionary = {
		"/": [6],
		"/auth": [7],
		"/auth/sign_in": [8],
		"/auth/sign_up": [9],
		"/bots": [~10],
		"/bots/new": [18],
		"/bots/[bot_id]/config": [11,[2]],
		"/bots/[bot_id]/connections": [12,[2,3]],
		"/bots/[bot_id]/connections/discord": [13,[2,3,4]],
		"/bots/[bot_id]/connections/discord/[conn_id]/conn": [~14,[2,3,4,5]],
		"/bots/[bot_id]/connections/discord/[conn_id]/servers": [~15,[2,3,4,5]],
		"/bots/[bot_id]/knowledge": [~16,[2]],
		"/bots/[bot_id]/link_knowledge_source": [~17,[2]],
		"/conversations": [~19],
		"/conversations/bots/[bot_id]": [~20],
		"/knowledge_sources": [~21],
		"/knowledge_sources/new": [25],
		"/knowledge_sources/new/document": [26],
		"/knowledge_sources/new/faq": [27],
		"/knowledge_sources/new/text": [28],
		"/knowledge_sources/[knowledge_source_id]": [~22],
		"/knowledge_sources/[knowledge_source_id]/edit": [~23],
		"/knowledge_sources/[knowledge_source_id]/faq": [~24],
		"/tags": [29],
		"/tags/training": [~30]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';